import React from "react"

const AddrChangeCost = () => {
  const title = `住所・氏名変更の費用例`
  const content = `弊所で住所・氏名変更登記をご依頼いただいた場合の費用例を下記にご紹介しましたのでご参照ください。
ただし、想定事例限定のものとなりますので、個別の御見積については、お気軽にお問い合わせ下さい。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="massyo__cost__exam">
          <h3>想定条件</h3>
          <ul>
            <li>住所変更登記：１件</li>
            <li>不動産：マンション専有部分１部屋・敷地１筆</li>
            <li>登記完了後の登記事項証明書　１通</li>
            <li>住民票等はお客様でご準備</li>
            <li>事務所来所等出張なしの手続</li>
          </ul>
          <h4>以上の条件による住所変更登記費用例</h4>
          <p>１７,０００円</p>
          <p>（報酬・実費・消費税（10%）込）</p>
        </div>
      </div>
    </div>
  )
}

export default AddrChangeCost
