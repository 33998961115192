import React from "react"

const AddrChangeSyorui = () => {
  const title = `住所・氏名変更手続`
  const content = `住所・氏名変更手続の登記には不動産１個につき、１,０００円の登録免許税という税金を納付する必要があります。

  例えば、戸建住宅で、土地１筆、建物１戸の場合は、２,０００円の登録免許税となります。
  マンションの場合、敷地の土地についても登録免許税が課税されるので、専有部分が１部屋、敷地権の土地が１筆の場合、戸建住宅と同様に２,０００円の登録免許税の納付が必要となります。
  
  共有の場合は原則として、共有者ごとに変更登記を申請する必要があるので、上記の登録免許税を共有者の人数分負担することになりますが、住所異動が同時に行われている場合等、登記原因が同一となる場合、例外として、１回の申請で同時に変更登記をすることができます。
  さらに、市町村合併や、住居表示実施など、行政区画の変更により住所が変わった場合は登録免許税は非課税となります。
 
  なお、その他に、申請対象物件の現状調査のための登記事項証明書等の取得費用や、申請後に、申請した通りに登記がなされているかを確認するための登記事項証明書等の取得費用も土地の筆数や、建物の戸数の分だけかかることになります。

  また、抵当権抹消登記や、贈与による所有権移転登記の前提として、所有者の登記されている住所・氏名が、現在の住所・氏名と異なる場合等には、併せて住所・氏名変更の登記をする必要があります。
 
  住所・氏名変更の登記手続きの主な必要書類は下記の通りとなります。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．住所変更の場合</dt>
            <dd>
              <ul>
                <li>住民票、または戸籍の附票</li>
                <li>委任状（司法書士に委任する場合）</li>
              </ul>
              <p>
                住民票には一部の例外を除いて前住所と住所の異動年月日が記載されています。その前住所と登記住所が一致していることが必要です。
                <br />
                市町村をまたいで複数回住所異動している場合、さらに前住所地で住民票除票というものを取得すると、住民票除票にも前住所の記載があるので、２つ前の住所が記載されています。
                <br />
                なお、同一市町村内で住所異動をしている場合、住民票発行の際、同一市町村内での住所異動の経緯を省略される場合があるので、市町村窓口で取得される際に、取得の趣旨をお伝えください。
                <br />
                次に戸籍の附票は、本籍地で取得できる住所異動の経緯が記載されたものです。複数回住所異動している場合には戸籍の附票を取得すると、複数の住民票や住民票除票を取得するよりも、コストをかけずに住所移動の経緯を証明できる場合があります。
                <br />
                しかし、婚姻や、市町村をまたぐ転籍をしている場合、新しい戸籍の作成とともに、戸籍の附票も新しくなるため、婚姻や転籍時の住所より前の住所の記載がされません。その場合は、戸籍の附票の除票を取得する必要があります。
                <br />
                必要な住民票や戸籍の附票をご自身で取得することが難しい場合、ご依頼いただければ有料対応となりますが、取得代行させていただきます。
              </p>
            </dd>

            <dt>２．氏名変更の場合</dt>
            <dd>
              <ul>
                <li>戸籍謄抄本（戸籍全部（一部）事項証明書）</li>
                <li>本籍地記載の住民票、または戸籍の附票</li>
                <li>委任状（司法書士に委任する場合）</li>
              </ul>
              <p>
                氏名変更の場合は、登記された氏名から現在の氏名への変更の経緯の分かるものが必要です。
                戸籍には従前戸籍が記載されていますので、例えば、婚姻により山田から鈴木へ姓の変更があった場合、
                従前戸籍欄に山田姓の戸籍筆頭者の名前が記載されて、令和〇〇年〇〇月〇〇日婚姻と、記載されている
                ものが必要となります。
                <br />
                必要な戸籍等をご自身で取得することが難しい場合、ご依頼いただければ有料対応となりますが、取得代行させていただきます。
              </p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default AddrChangeSyorui
