import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import AddrChangeSyorui from "../components/addrChangeDetail/addrChangeSyorui"
import AddrChangeCost from "../components/addrChangeDetail/addrChangeCost"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"

const Addrchange = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      meihen: file(relativePath: { eq: "meihen.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "住所や氏名が変更となった場合に必要な、不動産登記の住所、氏名の変更の登記手続きや費用について、大阪の司法書士事務所である司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="住所変更　氏名変更　登記 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.meihen.childImageSharp.original.src}
        pageimgw={data.meihen.childImageSharp.original.width}
        pageimgh={data.meihen.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"meihen"}
          fluid={data.meihen.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"住所・氏名変更"}
        />
        <section className="meihen">
          <div className="meihen__container">
            <h1 className="meihen__title">住所・氏名変更</h1>
            <AddrChangeSyorui />
            <AddrChangeCost />
            <BlogLink link={"addrchange"} linkName={"住所・氏名変更"} />
          </div>
        </section>
        <BackContact link={"/regist/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default Addrchange
